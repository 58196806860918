<template>
  <v-dialog v-model="bDialog" max-width="700">
    <template #activator="{ on }">
      <v-list color="grey lighten-3" rounded>
        <v-subheader>
          {{ $t("assign.branches") }}
          <v-spacer />
          <v-btn icon color="green" v-on="on">
            <icon-add size="1.2em" />
          </v-btn>
        </v-subheader>

        <template v-for="obBranch in arSelectedBranchList">
          <v-list-item v-on="on" :key="`user-assigned-company-${obBranch.id}`">
            <v-list-item-content>
              <branch-item-preview hide-title :item="obBranch" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>

    <v-card :loading="loading" v-if="bDialog">
      <v-list dark class="active" v-if="arSelectedCompanyList.length">
        <template v-for="(item, index) in arSelectedCompanyList">
          <v-list-item
            @click="del(item)"
            :key="`companies-added-list-item-${index}`"
          >
            <v-list-item-avatar>
              <icon-camera outlined size="1.8rem" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.firm.name" />
              <v-list-item-subtitle v-text="item.firm.email" />
            </v-list-item-content>
            <v-list-item-action>
              <icon-remove size="1.5em" class="red--text" />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <v-list dark class="active" v-if="selectedBranches.length">
        <template v-for="(item, index) in selectedBranches">
          <v-list-item
            @click="del(item)"
            :key="`companies-added-list-item-${index}`"
          >
            <v-list-item-avatar>
              <v-img
                :src="item.preview_image"
                :alt="item.firm.name"
                v-if="item.preview_image"
              />
              <icon-camera outlined size="1.8rem" v-else />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="`${item.firm.name} (${item.firm.dgi_dir_fiscal})`"
              />
              <v-list-item-subtitle v-text="item.firm.email" />
            </v-list-item-content>
            <v-list-item-action>
              <icon-remove size="1.5em" class="red--text" />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <v-card-text>
        <v-text-field
          :placeholder="$t('search.company')"
          :label="$t('search.company')"
          v-model="sSearch"
          ref="searchField"
        ></v-text-field>
      </v-card-text>

      <v-card-text v-if="arCompanyCollectionList.length">
        <v-expansion-panels>
          <template v-for="(item, index) in arCompanyCollectionList">
            <CompanyBranchesList
              :key="`company-branches-list-${index}`"
              :item="item"
              :arBranchList="arBranchList"
              @selection-applied="handleSelectionApplied"
            />
          </template>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn small depressed @click="bDialog = false" color="primary">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { BranchData, CompanyData } from "@planetadeleste/vue-mc-gw";
import { BranchCollection, CompanyCollection } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, Watch, VModel, Prop } from "vue-property-decorator";
import BranchItemPreview from "@/modules/companies/components/BranchItemPreview.vue";
import type { DebounceFunction } from "@/plugins/helpers";
import { debounce, difference, filter, uniq, uniqBy } from "lodash";
import CompanyBranchesList from "./CompanyBranchesList.vue";

@Component({
  components: {
    CompanyBranchesList,
    BranchItemPreview,
  },
})
export default class CompanyBranchesPicker extends Vue {
  bDialog = false;
  sSearch = "";
  fnDebounceSearch!: DebounceFunction;
  obCompanyCollection = new CompanyCollection();
  obBranchCollection = new BranchCollection();

  @VModel({ type: Array, default: () => [] }) arBranchList!: number[];
  @Prop(Boolean) readonly singleOption!: string;

  arBranchListId: number[] = [];
  selectedBranches: any[] = [];
  loading = false;

  @Watch("sSearch")
  onSearch() {
    this.fnDebounceSearch();
  }

  @Watch("bDialog")
  onOpenClose(bValue: boolean) {
    if (bValue) {
      this.load();
    } else {
      this.sSearch = "";
    }
  }

  get arSelectedCompanyList(): BranchData[] {
    //CompanyData[] {
    //const arItems = this.obCompanyCollection.getModelList() as CompanyData[];
    const arItems = this.obBranchCollection.getModelList() as BranchData[];
    return this.arBranchListId.length && arItems.length
      ? filter(arItems, (obItem) => this.arBranchListId.includes(obItem.id))
      : [];
  }

  get arSelectedBranchList(): BranchData[] {
    return this.selectedBranches;
  }

  get arCompanyCollectionList(): CompanyData[] {
    const arItems = this.obCompanyCollection.getModelList() as CompanyData[];

    return this.arBranchListId.length
      ? filter(arItems, (obItem) => !this.arBranchListId.includes(obItem.id))
      : arItems;
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    await this.search(true);

    if (!this.arBranchList.length) {
      return;
    }

    this.loading = true;
    this.arBranchListId = [...this.arBranchList];
    const obBranchCollection = new BranchCollection();
    console.log("eze lista", this.arBranchListId);
    obBranchCollection.page(1);
    await obBranchCollection.filterBy({ set: this.arBranchListId }).fetch();
    console.log("eze res ", obBranchCollection);
    this.selectedBranches = obBranchCollection.models;
    this.loading = false;

    /*
    const arCollectionIdList = this.obCompanyCollection.map("id") as number[];
    
    const arMissingIdList = difference(this.arBranchListId, arCollectionIdList);
    const obCollection = new BranchCollection();
    obCollection.page(1);
    await obCollection.filterBy({ set: arMissingIdList }).fetch();
    console.log('eze', obCollection);
    */
    /*
    if (!obCollection.length) {
      return;
    }

    obCollection.each((obModel) => {
      if (this.obCompanyCollection.find({ id: obModel.id })) {
        return;
      }

      this.obCompanyCollection.add(obModel);
    });
    */
  }

  async search(force: boolean = false) {
    if (!this.bDialog && !force) {
      return;
    }

    this.loading = true;
    const obCollection = new CompanyCollection();
    obCollection.page(1);

    if (this.sSearch.length > 1) {
      obCollection.filterBy({ search: this.sSearch });
    }

    await obCollection.fetch();
    this.obCompanyCollection.clear();
    this.obCompanyCollection.add(obCollection.models);
    this.loading = false;
  }

  add(obCompany: CompanyData) {
    const iCompanyId = obCompany.id;

    if (
      this.arBranchListId.includes(iCompanyId) ||
      !this.obCompanyCollection.find({ id: iCompanyId })
    ) {
      return;
    }
    if (this.singleOption) {
      this.arBranchListId = [];
    }
    this.arBranchListId.push(iCompanyId);
    this.apply();
  }

  del(obBranch: BranchData) {
    const idx = this.selectedBranches.findIndex(
      (branch) => branch.id === obBranch.id
    );

    if (idx < 0) {
      return;
    }

    this.selectedBranches.splice(idx, 1);
    this.arBranchListId = this.selectedBranches.map((branch) => branch.id);
    this.apply();
  }

  apply(close: boolean = false) {
    this.$emit(
      "input",
      this.arBranchListId.length ? uniq(this.arBranchListId) : []
    );

    if (close) {
      this.bDialog = false;
    }
  }

  handleSelectionApplied(selectedBranches: any[]) {
    this.selectedBranches.push(...selectedBranches);
    this.selectedBranches = uniqBy(this.selectedBranches, "id");
    const selectedBranchIds = this.selectedBranches.map((branch) => branch.id);
    this.arBranchListId = selectedBranchIds;
    this.apply();
  }
}
</script>
