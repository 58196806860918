<template>
  <v-row>
    <v-col cols="12" md="6" xl="4">
      <company-branches-picker v-model="branchListId" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import CompanyBranchesPicker from "@/modules/companies/components/CompanyBranchesPicker.vue";
import type { User } from "@planetadeleste/vue-mc-shopaholic";

@Component({
  components: { CompanyBranchesPicker },
})
export default class CompanyTab extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obUser!: User;

  get branchListId() {
    return this.obUser.get("branch_list_id");
  }

  set branchListId(arListId: number[]) {
    this.obUser.set("branch_list_id", arListId);
    this.$emit("input", this.obUser);
  }
}
</script>
